import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faBolt, faClock } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getSlot } from '../../utilities/requests';


dayjs.extend(customParseFormat)



export const REGIONS = [
    "aix_en_provence",
    "amiens",
    "angers",
    "angouleme",
    "annecy",
    "antibes",
    "aubagne",
    "avignon",
    "beauvais",
    "besancon",
    "beziers",
    "blois",
    "bordeaux",
    "boulogne_sur_mer",
    "brest",
    "brive",
    "caen",
    "calais",
    "cannes",
    "cazeres",
    "charleville_mezieres",
    "chembourg",
    "cholet",
    "clermont_ferrand",
    "colmar",
    "dijon",
    "dinard",
    "douai",
    "dunkerque",
    "evreux",
    "foix",
    "grenoble",
    "guerande",
    "la_rochelle",
    "le_havre",
    "le_mans",
    "lille",
    "limoges",
    "lyon",
    "marseille",
    "merignac",
    "merlimont",
    "metz",
    "montpellier",
    "mulhouse",
    "nancy",
    "nantes",
    "nice",
    "nimes",
    "niort",
    "orleans",
    "pamiers",
    "paris",
    "perigueux",
    "perpignan",
    "poitiers",
    "quimper",
    "reims",
    "rennes",
    "roubaix",
    "rouen",
    "saint_brieuc",
    "saint_etienne",
    "saint_nazaire",
    "saint_quentin",
    "strasbourg",
    "thionville",
    "toulon",
    "toulouse",
    "tours",
    "valence",
    "valenciennes",
    "vannes",
    "basildon",
    "birmingham", 
    "blackpool",
    "barcelona", 
    "girona", 
    "madrid", 
    "valles"
];
  

const dates = new Array(30).fill(dayjs()).map((m, i) => dayjs(m).add(i, "day"))

const getSlots = (startTime, endTime, interval) => {
    const next = dayjs(startTime).add(interval, "minute");
    const shouldInclude = startTime.isAfter(dayjs());
    const base = shouldInclude ? [ startTime ] : [];
    if (next.isAfter(endTime)) return [...base];
    return [...base, ...getSlots(next, endTime, interval)]
}

const getAddressZone = (address) => {
    try {
      const addressSubstrings = address.split(", ");
      const dropOffZone = addressSubstrings[addressSubstrings.length - 2]
        .replace(/\s/g, "_")
        .toLowerCase();
        
      return REGIONS.includes(dropOffZone) ? dropOffZone : "paris";
    } catch {
      return "paris";
    }
};

const getSlotsOfDay = async (date, address) => {
  const region = getAddressZone(address);
  try {
    const response = await getSlot({
        region: region,
        date: dayjs(date).format('YYYY-MM-DD')
    });

    return response.slots;
  } catch (error) {
  }
  const startTime = dayjs(date).hour(7).minute(0);
  const endTime = dayjs(date).hour(22).minute(30);
  const times = getSlots(startTime, endTime, 15);
  
  return times;
}

export const OrderDate = (props) => {
    const { t } = useTranslation();
    const [date, setDate] = useState(dates[0]);
    const [slots, setSlots] = useState([]);
    const [address, setAddress] = useState('');
    const [isLaterChecked, setIsLaterChecked] = useState(true);
    const setQuery = value => {
      const settedValue = value;
      props.setter(settedValue)
    }

    const updateDate = value => {
      setDate(value);
      setQuery(dayjs(value).format());
    };

    const updateSlot = e => { setQuery(e.target.value) }

    const setLater = () => {
      setQuery(dayjs(slots[0]).format())
      setIsLaterChecked(true)
    }

    const getFormattedSlot = (dateString) => {
        const timeMatch = dateString.match(/T(\d{2}:\d{2}):\d{2}/);
        return timeMatch ? timeMatch[1] : null;
    }


    useEffect(() => {
        setAddress(props.address);
    }, [props.address]);

    useEffect(() => {
        getSlotsOfDay(date, address).then((slots) => setSlots([...slots])).catch(() => {});
    }, [date, address])

    useEffect(() => {
        updateDate(dates[0])
    }, [])

    return (
        <Fragment>
            <Container className="App-default-component" id="orderDateAndTime">
                <Form>
                    <Row>
                        <Col xs={1}>
                            <FontAwesomeIcon icon={faClock} size="2x" color="#6e71e9" />
                        </Col>
                        <Col>
                            <h4>{t("orderDateTitle")}</h4>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={1}>
                            <Form.Check
                                id={2}
                                custom
                                label=""
                                type="radio"
                                checked={true}
                                onChange={setLater}
                            />
                        </Col>
                        <Col xs={2.5}>
                            <label> <FontAwesomeIcon icon={faCalendarAlt} /> {t("later")}</label>
                        </Col>
                    </Row>

                    {isLaterChecked && <Row>
                        <Col xs={6}>
                            {t("date")}
                            <br />
                            <Form.Control as="select" onChange={(event) => updateDate(dayjs(event.target.value, "DD MMMM YY"))}>
                                {dates.map((dateOption, index) =>
                                    <option key={index} value={dateOption.format("DD MMMM YY")}>{dateOption.format("DD MMMM")}</option>
                                )}
                            </Form.Control>
                        </Col>
                        <Col xs={6}>
                            {t("timeSlot")}
                            <br />
                            <Form.Control as="select" onChange={updateSlot} value={props.value || ""}>
                                {slots?.map((element, index) =>(<option key={index} value={
                                     element.start_time !== undefined ? element.start_time : dayjs(element).format(
                                    )}>
                                           {element.start_time !== undefined ?
                                            `${getFormattedSlot(element.start_time)} - ${getFormattedSlot(element.end_time)}`
                                            : `${element.format("HH:mm")} - ${dayjs(element).add(120, "minutes").format("HH:mm")}`
                                            }
                                          </option>))
                                }
                            </Form.Control>
                        </Col>
                    </Row>}
                </Form>
            </Container>
        </Fragment>
    );
};

export default OrderDate;
